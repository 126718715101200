
import { Component, Vue } from 'vue-property-decorator';
import VFormBuilder from '@/components/shared/form/VFormBuilder.vue';
import { InputType } from '@/enums/InputType';

@Component({
  components: { VFormBuilder },
})
export default class EditTagScoreDialog extends Vue {
  public initialValues: { scores?: any; tagNames?: any } = {};

  get items() {
    if (!this.initialValues.tagNames) {
      return [];
    }

    return Object.keys(this.initialValues.tagNames).map((key: string) => ({
      name: key,
      type: InputType.Select,
      label: this.initialValues.tagNames[key],
      items: this.selectableScores,
      default: 0,
    }));
  }

  get selectableScores() {
    return [
      { value: 10, text: `10 (${this.$t('recommendation.best')})` },
      { value: 9, text: '9' },
      { value: 8, text: '8' },
      { value: 7, text: '7' },
      { value: 6, text: '6' },
      { value: 5, text: '5' },
      { value: 4, text: '4' },
      { value: 3, text: '3' },
      { value: 2, text: '2' },
      { value: 1, text: '1' },
      { value: 0, text: `0 (${this.$t('recommendation.worst')})` },
    ];
  }

  public $refs!: {
    form: InstanceType<typeof VFormBuilder> & { getData: () => any; validate: () => Promise<boolean | boolean[]> };
  };

  public isVisible: boolean = false;

  public resolve: (value?: any) => any = () => null;

  public open(resolve: (value?: any) => any = () => null, initialValues?: { scores?: any; tagNames?: any }) {
    if (initialValues) {
      this.initialValues = initialValues;
    }
    this.isVisible = true;
    this.resolve = resolve;
  }
  public agree() {
    this.resolve({ scores: this.$refs.form.getData() });
    this.isVisible = false;
  }
  public cancel() {
    this.resolve();
    this.isVisible = false;
  }
}
