
import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import VTable from '@/components/shared/table/VTable.vue';
import VWrapper from '@/components/shared/VWrapper.vue';
import EditTagScoreDialog from '@/components/recommendation/EditTagScoreDialog.vue';
import { CartRecommendation } from '@/interfaces/models/CartRecommendation';
import { TagScoreMatrix, TagScoreMatrixRow } from '@/interfaces/recommendation/TagScoreMatrix';
import VFormBuilder from '@/components/shared/form/VFormBuilder.vue';
import { InputType } from '@/enums/InputType';
import { Tag } from '@/interfaces/models/Tag';
import { Venue } from '@/interfaces/models/Venue';
import Filter from '@/interfaces/api/Filter';
import { Category } from '@/interfaces/models/Category';
import { Article } from '@/interfaces/models/Article';
import CartRecommendationApiService from '@/api/http/CartRecommendationApiService';
import { AxiosResponse } from 'axios';
import { CartRecommendationTagResult } from '@/interfaces/recommendation/CartRecommendationTagResult';
import { CartRecommendationType } from '@/enums/CartRecommendationType';

const venue = namespace('venue');
const tag = namespace('tag');
const cartRecommendation = namespace('cartRecommendation');
const foodcard = namespace('foodcard');

@Component({
  components: { VFormBuilder, VTable, VWrapper, EditTagScoreDialog },
})
export default class Recommendation extends Vue {
  @venue.State('active') public venue!: Venue;

  @tag.Action('setFilter') public setTagFilter!: (filter: Filter) => void;
  @tag.State('items') public tags!: Tag[];
  @tag.Action('fetch') public getTags!: any;

  @cartRecommendation.State('filter') public cartRecommendationFilter!: Filter;
  @cartRecommendation.Action('setFilter') public setCartRecommendationFilter!: (filter: Filter) => void;
  @cartRecommendation.State('active') public cartRecommendation!: CartRecommendation;
  @cartRecommendation.Action('showByVenue') public getCartRecommendation!: any;
  @cartRecommendation.Action('update') public updateCartRecommendation!: any;
  @cartRecommendation.Action('store') public createCartRecommendation!: any;

  @foodcard.State('categories') public categories!: Category[];
  @foodcard.State('articles') public articles!: Article[];
  @foodcard.State('articleFilter') public articleFilter!: Filter;
  @foodcard.Action('setCategoryFilter') public setCategoryFilter!: any;
  @foodcard.Action('fetchCategories') public getCategories!: any;
  @foodcard.Action('setArticleFilter') public setArticleFilter!: any;
  @foodcard.Action('fetchArticles') public getArticles!: any;

  public category: string = '';
  public selectedArticles: string[] = [];
  public selectedArticlesAll: { [key: string]: Article } = {};
  public selectedTags: { [key: string]: Tag } = {};
  public recommendationResults: CartRecommendationTagResult[] | null = null;

  public $refs!: {
    editScoreDialog: InstanceType<typeof EditTagScoreDialog>;
    formAddScore: InstanceType<typeof VFormBuilder> & {
      getData: () => any;
      validate: () => Promise<boolean | boolean[]>;
    };
  };

  public async mounted() {
    this.$startLoading('tagScores');

    if (!this.venue) {
      return;
    }

    this.setTagFilter({ venue: this.venue._id, recommendationTagsOnly: true });
    this.setCartRecommendationFilter({ venue: this.venue._id, orderType: 'preorder', subOrderType: 'takeAway' });
    this.setCategoryFilter({ venue: this.venue._id });
    await Promise.all([this.getTags(), this.getCartRecommendation({ venue: this.venue._id }), this.getCategories()]);

    this.$stopLoading('tagScores');
  }

  @Watch('venue')
  public async onVenueChange() {
    if (this.venue && this.venue._id) {
      this.$startLoading('tagScores');
      this.setTagFilter({ venue: this.venue._id, recommendationTagsOnly: true });
      this.setCartRecommendationFilter({ venue: this.venue._id, orderType: 'preorder', subOrderType: 'takeAway' });
      this.setCategoryFilter({ venue: this.venue._id });
      await Promise.all([this.getTags(), this.getCartRecommendation({ venue: this.venue._id }), this.getCategories()]);
      this.$stopLoading('tagScores');
    }
  }

  get tagScoreMatrixColumns() {
    const tags: Tag[] = this.tags || [];

    const columns: any[] = tags.map((tag: Tag) => ({
      text: this.$options.filters!.localized(tag.name),
      value: tag.identifier,
    }));

    return [{ text: '', value: 'tagName' }, ...columns];
  }

  get tagScoreMatrixRows() {
    const matrix: TagScoreMatrix | undefined =
      this.cartRecommendation && this.cartRecommendation.data && this.cartRecommendation.data.matrix;

    if (!matrix) {
      return [];
    }

    return matrix.rows.map((row: TagScoreMatrixRow) => {
      const scores: any = {};

      for (const score of row.scores) {
        scores[score.tag] = score.score;
      }

      const tag: Tag | undefined = this.getArticleTag(row.tag);

      return {
        tagId: (tag && tag.identifier) || row.tag,
        tagName: (tag && this.$options.filters!.localized(tag.name)) || row.tag,
        ...scores,
      };
    });
  }

  get addScoreItems() {
    const matrix: TagScoreMatrix | undefined =
      this.cartRecommendation && this.cartRecommendation.data && this.cartRecommendation.data.matrix;
    const rowTagIds: string[] = matrix ? matrix.rows.map((row: TagScoreMatrixRow) => row.tag) : [];
    const filteredTags: Tag[] = this.tags.filter((tag: Tag) => !rowTagIds.includes(tag.identifier));
    const items: any[] = filteredTags.map((tag: Tag) => {
      return {
        value: tag.identifier,
        text: this.$options.filters!.localized(tag.name),
      };
    });

    return [{ name: 'identifier', type: InputType.Select, label: 'Tag', items }];
  }

  get articlesWithTag() {
    return this.articles.filter((article: Article) => article.tags && article.tags.length > 0);
  }

  public getArticleTag(identifier: string) {
    const tags: Tag[] = this.tags || [];
    return tags.find((tag: Tag) => tag.identifier === identifier);
  }

  public getArticleTagById(id: string) {
    const tags: Tag[] = this.tags || [];
    return tags.find((tag: Tag) => tag._id === id);
  }

  public async addScore() {
    const selectedTag: Partial<Tag> = this.$refs.formAddScore && this.$refs.formAddScore.getData();

    if (!selectedTag.identifier) {
      return;
    }

    // TODO - refactor this coder
    if (!this.cartRecommendation) {
      await this.createCartRecommendation({
        name: selectedTag.identifier,
        venue: this.venue._id,
        type: CartRecommendationType.SCORE_MATRIX,
        data: {
          matrix: {
            rows: [],
          },
        },
      });
      await this.getCartRecommendation({ venue: this.venue._id });
    }

    const recommendation: CartRecommendation | null = this.cartRecommendation;

    const matrix: TagScoreMatrix = (recommendation && recommendation.data && recommendation.data.matrix) || {
      rows: [],
    };

    const row: TagScoreMatrixRow = {
      tag: selectedTag.identifier,
      scores: this.tags.map((tag: Tag) => ({
        tag: tag.identifier,
        score: 0,
      })),
    };

    await this.updateCartRecommendation({
      ...recommendation,
      id: recommendation._id,
      data: { matrix: { ...matrix, rows: [...matrix.rows, row] } },
    });
  }

  public deleteScoreRow(clickedRow: any): void {
    const recommendation: CartRecommendation | null = this.cartRecommendation;

    if (!recommendation || !recommendation.data) {
      return;
    }

    const matrix: TagScoreMatrix = (recommendation && recommendation.data && recommendation.data.matrix) || {
      rows: [],
    };

    const rows: TagScoreMatrixRow[] = matrix.rows.filter((row: TagScoreMatrixRow) => row.tag !== clickedRow.tagId);

    this.updateCartRecommendation({ ...recommendation, id: recommendation._id, data: { matrix: { rows } } });
  }

  public showEditScoreDialog(clickedRow: any): void {
    const scores: any = {};
    const tagNames: any = {};

    for (const key of Object.keys(clickedRow)) {
      if (['tagId', 'tagName'].includes(key)) {
        continue;
      }

      const tag: Tag | undefined = this.getArticleTag(key);

      scores[key] = clickedRow[key];
      tagNames[key] = (tag && this.$options.filters!.localized(tag.name)) || key;
    }

    for (const tag of this.tags) {
      if (clickedRow[tag.identifier]) {
        continue;
      }

      scores[tag.identifier] = 0;
      tagNames[tag.identifier] = this.$options.filters!.localized(tag.name);
    }

    this.$refs.editScoreDialog.open(
      async (data: { scores: any } | PromiseLike<{ scores: any }> | undefined) => {
        const result: { scores: any } | PromiseLike<{ scores: any }> | undefined = await data;
        if (result) {
          const recommendation: CartRecommendation | null = this.cartRecommendation;

          if (!recommendation || !recommendation.data) {
            return;
          }

          const matrix: TagScoreMatrix = (recommendation && recommendation.data && recommendation.data.matrix) || {
            rows: [],
          };

          const rows: TagScoreMatrixRow[] = matrix.rows.map((row: TagScoreMatrixRow) => {
            if (row.tag !== clickedRow.tagId) {
              return row;
            }

            return {
              tag: row.tag,
              scores: Object.keys(result.scores).map((key: string) => ({
                tag: key,
                score: Number(result.scores[key]),
              })),
            };
          });

          this.updateCartRecommendation({ ...recommendation, id: recommendation._id, data: { matrix: { rows } } });
        }
      },
      { scores, tagNames },
    );
  }

  public async onCategoryChange(category: string) {
    if (category !== this.articleFilter.category) {
      this.selectedArticles = [];
      this.setArticleFilter({ category });
      this.$startLoading('article');
      await this.getArticles();
      this.$stopLoading('article');
    }
  }

  public onSelectedArticleChange(articleIds: string[]) {
    for (const articleId of articleIds) {
      if (this.selectedArticlesAll[articleId]) {
        continue;
      }

      const article: Article | undefined = this.articles.find((a: Article) => a._id === articleId);

      if (!article) {
        continue;
      }

      this.selectedArticlesAll[articleId] = article;

      for (const tagId of article.tags || []) {
        const tag: Tag | undefined = this.getArticleTagById(tagId as string);

        if (!tag || this.selectedTags[tag.identifier]) {
          continue;
        }

        this.selectedTags[tag.identifier] = tag;
      }
    }
  }

  public clearAllSelectedArticles() {
    this.selectedArticlesAll = {};
    this.selectedTags = {};
  }

  public getRecommendations() {
    this.$startLoading('recommendations');
    const articles: Array<{
      // @ts-ignore
      _id: string;
      quantity: number;
    }> = Object.keys(this.selectedArticlesAll).map((key: string) => ({
      // @ts-ignore
      _id: key,
      quantity: 1,
    }));

    const api: CartRecommendationApiService = new CartRecommendationApiService();
    api
      .recommendation(this.cartRecommendationFilter, articles)
      .then((res: AxiosResponse<CartRecommendationTagResult[]>) => {
        this.recommendationResults = res.data;
      })
      .finally(() => {
        this.$stopLoading('recommendations');
      });
  }
}
